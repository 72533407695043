import { createAsyncThunk } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';
import { Moengage } from '~global/sociolla.dm.lib';
export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async (params) =>
    await Catalog.categoriesRequest(params).then(({ data: { data } }) => data),
);

export const getCategoriesChildren = createAsyncThunk(
  'categories/getCategoriesChildren',
  async (params) => {
    const response = await Catalog.categoriesChildrenRequest(params);
    const categories = response.data.data.children;
    const subcategories = {};
    const childSubcategories = {};
    const grandChildSubcategories = {};

    try {
      categories.map((category) => {
        subcategories[category.slug] = {
          data: category.children,
          name: category.name,
          slug: category.slug,
          type: category.slug,
          _id: category._id,
        };
        category.children.map((subcategory) => {
          childSubcategories[`${subcategory._id}_${category.slug}`] = {
            data: subcategory.children,
            name: subcategory.name,
            slug: subcategory.slug,
            type: category.slug,
            _id: subcategory._id,
          };
          subcategory.children.map((childSubcategory) => {
            grandChildSubcategories[
              `${childSubcategory._id}_${category.slug}`
            ] = {
              data: childSubcategory.children,
              name: childSubcategory.name,
              slug: childSubcategory.slug,
              type: category.slug,
              _id: childSubcategory._id,
            };
          });
        });
      });
    } catch (err) {
      console.log(err);
    }

    response.data.data = {
      categories,
      subcategories,
      childSubcategories,
      grandChildSubcategories,
    };
    return response.data;
  },
);

export const getAllProductsRequest = createAsyncThunk(
  'categories/getAllProductsRequest',
  async ({ slug, type, params }) => {
    const response = await Catalog.getAllProducts({ slug, params, type });
    response.data.slug = slug;
    return response.data;
  },
);

export const getTotalProductsRequest = createAsyncThunk(
  'categories/getTotalProductsRequest',
  async ({ slug, params, type }) => {
    const response = await Catalog.getTotalProducts({ slug, params, type });
    response.data.slug = slug;
    return response.data;
  },
);

export const loadMoreAllProductsRequest = createAsyncThunk(
  'categories/loadMoreAllProductsRequest',
  async ({ slug, type, params }) => {
    const response = await Catalog.getAllProducts({ slug, params, type });
    response.data.slug = slug;
    return response.data;
  },
);

export const getCategoryDetail = createAsyncThunk(
  'categories/getCategoryDetail',
  async (slug) => {
    const response = await Catalog.getCategoryDetail(slug);
    response.data.slug = response.data.data.slug;
    Moengage.viewCategories(response.data.data);
    return response.data;
  },
);

export const getBannersPromotion = createAsyncThunk(
  'categories/getBannersPromotion',
  async (slug) => {
    const response = await Catalog.getBannersPromotion(slug);
    response.slug = slug;
    return response.data;
  },
);
