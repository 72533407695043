import { Catalog } from '~api/catalog';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const WISHLIST_DATA = 'WISHLIST_DATA';
export const JUST_LANDED_DATA = 'JUST_LANDED_DATA';

export const getProducts = (payload) => {
  return async () => {
    try {
      const res = await Catalog.productsRequest(payload);
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) { }
  };
};

export const getWishlist = createAsyncThunk(WISHLIST_DATA, async (params) => {
  try {
    const res = await Catalog.wishlistRequest(params);
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) { }
});

export const getWishlistCount = () => {
  return async () => {
    try {
      const res = await Catalog.wishlistCountRequest();
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getBanners = (filter) => {
  return async () => {
    try {
      const res = await Catalog.bannersAllRequest(filter);
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSimilarProducts = (payload) => {
  return async () => {
    try {
      const res = await Catalog.similarProductRequest(payload);
      if (res.status == 200) {
        return res.data.data;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProductsJustLanded = createAsyncThunk(
  JUST_LANDED_DATA,
  async () => {
    try {
      const res = await Catalog.productJustLandedRequest({
        skip: 0,
        limit: 10,
        filter: {
          is_active_in_lulla: true,
          type: { $in: ['whats_new_product'] },
        },
        sort: '-created_at',
      });
      if (res.status == 200 && res.data.data.length > 0) {
        // return res.data.data[0].products;
        return res.data.data.sort((a, b) => a.position - b.position);
      }
    } catch (err) {
      throw new Error('Something went wrong!');
    }
  },
);

export const getTagsByLevelName = createAsyncThunk(
  'catalog/tags',
  async (level_name) =>
    await Catalog.tagsRequest({
      filter: {
        level_name,
        is_filter: true,
        is_active_for_lulla: true,
      },
      limit: 99,
    })
      .then(({ data: { data } }) => {
        return data.map((item) => ({
          name: item.name,
          value: item.code,
          code: item.code,
          isSelected: false,
        }));
      })
      .catch(console.log),
);

export const getYouMightAlsoLikeProducts = createAsyncThunk(
  'catalog/getYouMightAlsoLikeProducts',
  async (params) => {
    const response = await Catalog.getProductsRequest(params);
    return response.data.data;
  },
);

export const loadMoreYouMightAlsoLikeProducts = createAsyncThunk(
  'catalog/loadMoreYouMightAlsoLikeProducts',
  async (params) => {
    const response = await Catalog.getProductsRequest(params);
    return response.data.data;
  },
);

export const getTotalYouMightAlsoLikeProducts = createAsyncThunk(
  'catalog/getTotalYouMightAlsoLikeProducts',
  async (params) => {
    const response = await Catalog.getTotalProductsRequest(params);
    return response.data.data;
  },
);
